import React, { useState } from "react";

import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import $ from 'jquery';
import { wrapper } from 'axios-cookiejar-support';
import * as app from "../fetch/fetchApi";


import "react-datepicker/dist/react-datepicker.css";
const axios = require('axios');

const jar = app.jar;
const client = wrapper(axios.create({ jar }));
registerLocale('es', es)
const CompraTicketsMobile = (data) => {
  console.log(data)
  const [alerta, setAlerta] = useState('');

  const [startDate, setStartDate] = useState(null);
  const [startDate2, setStartDate2] = useState(null);



  const addMonths = () => {
    var fechaHoy = new Date(Date.now())

    var fechaFinal = new Date()
    fechaFinal.setDate(fechaHoy.getDate() + 180)
    console.log(fechaFinal)
    return fechaFinal
  }

  const busqueda = () => {
    console.log('fecha inicio ' + startDate)
    console.log('fecha fin ' + startDate2)
    var d1 = new Date(startDate);
    var d2 = new Date(startDate2);


    if (startDate == null || startDate2 == null) {
      console.log('clic')
      document.getElementById('toast-message').style.display = 'block'
      // $('#toast-message').css('display','block')
      setTimeout(() => {
        // $('#toast-message').css('display','none')
        document.getElementById('toast-message').style.display = 'none'

      }, 2000);

    } if (d1.getDate() === d2.getDate()) {
      console.log('entro')
      document.getElementById('toast-message').style.display = 'block'
      // $('#toast-message').css('display','block')
      setTimeout(() => {
        // $('#toast-message').css('display','none')
        document.getElementById('toast-message').style.display = 'none'

      }, 2000);

    }
    else if (d1.getDate() < d2.getDate()) {
      console.log('fecha inicio ' + startDate)
      console.log('fecha fin ' + startDate2)
      let dateInicio = [
        startDate.getFullYear(),
        ('0' + (startDate.getMonth() + 1)).slice(-2),
        ('0' + startDate.getDate()).slice(-2)
      ].join('-');

      let dateFin = [
        startDate2.getFullYear(),
        ('0' + (startDate2.getMonth() + 1)).slice(-2),
        ('0' + startDate2.getDate()).slice(-2)
      ].join('-');
      console.log('fecha inicio final ' + dateInicio)
      console.log('fecha fin final ' + dateFin)
      localStorage.setItem('nombreSitio', 'todos')
      const isBrowser = typeof window !== "undefined"

      if (isBrowser) {
        window.location.href = '/reserva?fechaInicio=' + dateInicio + "&fechaFin=" + dateFin
      }
    }
  }






  function postData(url = '', data = {}) {
    // Opciones por defecto estan marcadas con un *
    const response = fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return 'ok'; // parses JSON response into native JavaScript objects
    //return console.log('ok')
  }

  setTimeout(() => {
    handleLocalStorage()
    $("#formulario-rep").on("submit", function (event) {


      console.log('prubae')
      if ($("#edit-field-entrada-general-und-0-value-rep").val() != 0 || $("#edit-field-entrada-menor-und-0-value-rep").val() != 0) {

        console.log($("#edit-field-entrada-general-und-0-value-rep").val())
        console.log($("#edit-field-entrada-menor-und-0-value-rep").val())

        if ($("#edit-field-entrada-general-und-0-value-rep").val() != 0 || $("#edit-field-entrada-menor-und-0-value-rep").val() != 0) {
          var formData = []
          if ($("#edit-field-entrada-general-und-0-value-rep").val() != 0 && $("#edit-field-entrada-menor-und-0-value-rep").val() == 0) {
            formData.push({ id: data.data[0].idproducto, cantidad: $("#edit-field-entrada-general-und-0-value-rep").val() })
          } if ($("#edit-field-entrada-general-und-0-value-rep").val() == 0 && $("#edit-field-entrada-menor-und-0-value-rep").val() != 0) {
            formData.push({ id: data.data[1].idproducto, cantidad: $("#edit-field-entrada-menor-und-0-value-rep").val() })

          } else if ($("#edit-field-entrada-general-und-0-value-rep").val() != 0 && $("#edit-field-entrada-menor-und-0-value-rep").val() != 0) {
            formData.push({
              id: data.data[0].idproducto, cantidad: $("#edit-field-entrada-general-und-0-value-rep").val()
            }, {
              id: data.data[1].idproducto, cantidad: $("#edit-field-entrada-menor-und-0-value-rep").val(),
            })
          }

          var datos = JSON.stringify(formData, 4, null)
          client.post(app.url + '/node-api/m22_insert_to_carrito/', datos, {
            // method: 'POST', // or 'PUT'
            // credentials: 'include',
            withCredentials: true,
            //jar:CookieJar,
            //data: datos, // data can be `string` or {object}!
            headers: {
              // Overwrite Axios's automatically set Content-Type
              crossDomain: true,
              'Content-Type': 'application/json'
            },
          })
            .then(function (response) {
              console.log(response);
              console.log('Success:', response)
              //console.log(jar.getCookiesSync('https://silvio.estacas.dev.m22.mx/node-api/m22_insert_to_carrito/'));
              setAlerta('success')
              setTimeout(() => {
                setAlerta('')
                //window.location.reload();
                peticion()
              }, 3000);
            })
            .catch(function (error) {
              console.log(error);
              console.error('Error:', error)
              setAlerta('error')
              setTimeout(() => {
                setAlerta('')
              }, 3000);
            });
          $('#formulario')[0].reset();
          event.preventDefault()
        }
      } else if ($("#edit-field-entrada-general-und-0-value-rep").val() == 0 && $("#edit-field-entrada-menor-und-0-value-rep").val() == 0) {
        setAlerta('error')
        console.log(alerta)
        setTimeout(() => {
          setAlerta('')
        }, 3000);
      }
    })
  }, 2000);


  var precioTotal = 0
  var cantidadGeneral = 0;
  var cantidadMenor = 0;
  const suma = (precio) => {
    console.log(precio)
    console.log(data.data[0].precio)

    if (precio == data.data[0].precio) {
      cantidadGeneral = cantidadGeneral + 1
      console.log(cantidadGeneral)
      document.getElementById('edit-field-entrada-general-und-0-value-rep').value = cantidadGeneral;
    } else if (precio == data.data[1].precio) {
      cantidadMenor = cantidadMenor + 1;
      console.log(cantidadMenor)
      document.getElementById('edit-field-entrada-menor-und-0-value-rep').value = cantidadMenor;


    }
    precio = precio.replace('$', '')
    let precioTotalFloat = parseFloat(precio)
    precioTotal = precioTotalFloat + precioTotal;
    console.log(precioTotal)
    document.getElementById('edit-field-visitantes-und-0-value-rep').value = '$' + precioTotal
    document.getElementById('visita-rep').innerHTML = (cantidadGeneral + cantidadMenor) + ' visitantes'

  }

  const resta = (precio) => {
    var precio2 = precio
    precio = precio.replace('$', '')
    let precioTotalFloat = parseFloat(precio)
    if (precio2 == data.data[0].precio && document.getElementById('edit-field-entrada-general-und-0-value-rep').value > 0) {
      cantidadGeneral = cantidadGeneral - 1
      console.log(cantidadGeneral)
      document.getElementById('edit-field-entrada-general-und-0-value-rep').value = cantidadGeneral;
      precioTotal = precioTotal - precioTotalFloat;
    } else if (precio2 == data.data[1].precio && document.getElementById('edit-field-entrada-menor-und-0-value-rep').value > 0) {
      cantidadMenor = cantidadMenor - 1;
      console.log(cantidadMenor)
      document.getElementById('edit-field-entrada-menor-und-0-value-rep').value = cantidadMenor;
      precioTotal = precioTotal - precioTotalFloat;


    }
    document.getElementById('edit-field-visitantes-und-0-value-rep').value = '$' + precioTotal
    document.getElementById('visita-rep').innerHTML = (cantidadGeneral + cantidadMenor) + ' visitantes'
    console.log(precioTotal)
  }


  async function peticion() {


    await client.get(app.url + '/node-api/m22_get_cantidad/1', {
      // method: 'POST', // or 'PUT'
      // credentials: 'include',
      withCredentials: true,
      //jar:CookieJar,
      //data: datos, // data can be `string` or {object}!
      headers: {
        // Overwrite Axios's automatically set Content-Type
        crossDomain: true,
        'Content-Type': 'application/json'
      },
    })
      .then(function (response) {
        // setData(response.data[0])
        localStorage.setItem('cantidadProds', response.data[0])
        //cantidad=response.data[0]
        console.log(response.data[0]);

      })
      .catch(function (error) {
        console.log(error)
      });
  }

  const handleLocalStorage = () => {
    window.dispatchEvent(new Event("storage"));
  };

  return (
    <div style={{ padding: '18px 20px', height: '459px' }} className={'block bloque d-block d-md-none mb-4'}>

      <div className="tabs">
        <Tabs>
          <Tab class="mr-0 " label="Entradas">
            <div id="clientsidevalidation-compra-tickets-entityform-edit-form-errors" class="messages error clientside-error" style={{ display: 'none' }}><ul></ul></div>
            <form class="entityform entitytype-compra_tickets-form" action="javascript:void(0);" method={'post'} id={'formulario-rep'} accept-charset="UTF-8" novalidate="novalidate"><div class="general"><div class="pre-instructions"></div>
              <div class="required-fields group-wrapper-form d-flex field-group-html-element" style={{ width: '100%' }}>
                <div class="required-fields group-entradas field-group-div visible  ms-0" >

                  <div class=" d-flex row field-type-number-integer field-name-field-entrada-general field-widget-number form-wrapper d-flex row" id="edit-field-entrada-general" style={{ width: '100%' }}><div id="field-entrada-general-add-more-wrapper" className="px-0">
                    <div class="d-flex form-item form-type-textfield form-item-field-entrada-general-und-0-value">



                      <div class="input-group  d-flex justify-content-between " style={{ display: 'unset', width: '100%', alignItems: 'baseline' }} >
                        <div className="col-5 d-flex"> <p for="edit-field-entrada-general-und-0-value" id="productos" data-precio={data.data[0].precio} data-sku={data.data[0].sku} data-componente="ticket home" class="letraReserva mb-0 text-start">General</p>  <div className="tooltips ms-3">?<span data-tooltip="" class="  tooltiptext entradaGeneral" data-selector="tooltip-kt21gffp1" aria-describedby="tooltip-kt21gffp1" >Mide mas de 1.25m.</span> </div></div>
                        <div>
                          <button type="button" onClick={() => { resta(data.data[0].precio) }} class="less button">-</button>
                          <input type="number" id="edit-field-entrada-general-und-0-value-rep" name="field_entrada_general[und][0][value]" data-precio={data.data[0].precio} data-sku={data.data[0].sku} value="0" size="12" maxlength="10" class="form-text label-up data" />
                          <button type="button" onClick={() => { suma(data.data[0].precio) }} class="more button">+</button>
                        </div>
                      </div>
                    </div>
                  </div></div>

                  <div class="field-type-number-integer field-name-field-entrada-menor field-widget-number form-wrapper row my-3" id="edit-field-entrada-menor">
                    <div className="px-0" id="field-entrada-menor-add-more-wrapper">
                      <div class="form-item form-type-textfield form-item-field-entrada-menor-und-0-value">



                        <div class="input-group  d-flex justify-content-between " style={{ display: 'unset', width: '100%', alignItems: 'baseline' }}>
                          <div className="d-flex">
                            <p for="edit-field-entrada-menor-und-0-value " id="productos" data-precio={data.data[1].precio} data-sku={data.data[1].sku} data-componente="ticket home" class="letraReserva text-start">Menor 1.25m</p><div className="tooltips ms-3">?<span data-tooltip="" class="tooltiptext" data-selector="tooltip-kt21gffp1" aria-describedby="tooltip-kt21gffp1" title="En caso de no acreditar la estatura, tendrás que pagar la diferencia en taquilla.">En caso de no acreditar la estatura, tendrás que pagar la diferencia en taquilla.</span> </div>
                            {/*<span data-tooltip="" class="has-tip tip-top" data-selector="tooltip-kt21gffp1" aria-describedby="tooltip-kt21gffp1" title="">?</span>*/}
                          </div>
                          <div>
                            <button type="button" onClick={() => { resta(data.data[1].precio) }} class="less button">-</button>
                            <input type="number" id="edit-field-entrada-menor-und-0-value-rep" data-precio={data.data[1].precio} data-sku={data.data[1].sku} name="field_entrada_menor[und][0][value]" value="0" size="12" maxlength="10" class="form-text label-up data" />
                            <button type="button" onClick={() => { suma(data.data[1].precio) }} class="more button">+</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="required-fields group-summary-form field-group-html-element resumen-group row" >
                    <div class="d-flex justify-content-between px-0">
                      <p className={'letraReserva'}>Resumen</p>
                      <div class="field-type-text field-name-field-visitantes field-widget-text-textfield form-wrapper " id="edit-field-visitantes">
                        <div id="field-visitantes-add-more-wrapper">
                          <div class="d-flex justify-content-end">
                            <label for="edit-field-visitantes-und-0-value" id="visita-rep" class="label-up data visitantes mt-1 col-8 align-middle text-end">
                            </label>
                            <input class="text-full form-text label-up data labelNormal text-end align-baseline" style={{ border: '0px', boxShadow: 'unset', paddingTop: '0px' }} type="text" id="edit-field-visitantes-und-0-value-rep" name="field_visitantes[und][0][value]" value="$0" size="60" maxlength="50" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {alerta == 'success' ? <div id="add-to-cart-toast-message" style={{ visibility: 'visible' }} class="paragraph-sb">Producto agregado a carrito</div> : ''}
                  {alerta == 'error' ? <div id="toast-message" class=""><span class="paragraph-sb"><strong>Error:</strong> por favor ingresa un número de entradas.</span></div> : ''}


                </div></div>
              <div className="mt-2">
                <input type="hidden" name="form_build_id" value="form-4yv-qdkwR7V4OtjbkrT-I1MWl1i2vxoxVo1EkYUf4ok" />
                <input type="hidden" name="form_id" value="compra_tickets_entityform_edit_form" />
                <button class="secondary button radius form-submit mb-4 palabraBoton" id="agregarCarrito" style={{ marginTop: '0px', width: '124px', height: '48px', lineHeight: '16px' }} name="op" value="comprar" type="submit">Comprar</button>
                <p class="paragraph-sb tarifas mt-2" style={{ fontSize: '16px' }}>*Para más información sobre precios puedes visitar nuestra sección de <a href={app.url + "/tarifas"} class="btn-tex-mob-sb">Tarifas</a></p>

              </div>
            </div>
            </form>
          </Tab>
          <Tab label="Hospedaje">
            <div style={{ marginBottom: '10px' }} className={'mt-2'}>
              <div className={'d-flex'}>
                <label style={{ marginBottom: 0, alignSelf: 'center', fontSize: '16px', display: 'block', width: '40%' }} className={'descripcion text-start'}>Entrada</label>
                {/*<Calendar className={'col-4'} />*/}
                <div className={""}>
                  <DatePicker selected={startDate} dateFormat="dd/MM/yyyy" onChange={(date) => setStartDate(date)} locale="es" placeholderText="Fecha" className={'calendario'} minDate={new Date() - 5} />
                </div>
              </div>
              <div className={'d-flex my-4'}>
                <label style={{ marginBottom: 0, alignSelf: 'center', fontSize: '16px', display: 'block', width: '40%' }} className={'descripcion text-start'}>Salida</label>
                <div className={""}>
                  <DatePicker selected={startDate2} dateFormat="dd/MM/yyyy" maxDate={addMonths()} onChange={(date) => setStartDate2(date)} locale="es" className={"col-4"} placeholderText="Fecha" className={'calendario'} minDate={new Date() - 5} />
                </div>
              </div>
              {/*
             <Calendar2 />
  */}
              <div style={{ marginTop: '58px' }}>
                <button class="secondary button col-2 radius form-submit mt-0 palabraBoton mb-4" id="edit-submit--4" onClick={() => busqueda()} name="op" value="comprar" style={{ width: '128px', height: '48px', lineHeight: '16px' }} type="submit">Buscar</button>
              </div>
            </div>

            <p class="paragraph-sb tarifas  mt-2" style={{ fontSize: '16px', display: 'block' }}>*Para más información sobre precios puedes visitar nuestra sección de <a href={app.url + "/tarifas"} class="btn-tex-mob-sb links">Tarifas</a></p>

          </Tab>
        </Tabs>
      </div>


    </div>
  );
};


class Tabs extends React.Component {
  state = {
    activeTab: this.props.children[0].props.label
  }
  changeTab = (tab) => {

    this.setState({ activeTab: tab });
  };
  render() {

    let content;
    let buttons = [];
    return (
      <div>
        {React.Children.map(this.props.children, child => {
          buttons.push(child.props.label)
          if (child.props.label === this.state.activeTab) content = child.props.children
        })}

        <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab} />
        <div className="tab-content">{content}</div>

      </div>
    );
  }
}
const TabButtons = ({ buttons, changeTab, activeTab }) => {

  return (
    <div className="tab-buttons2 d-flex mb-0 justify-content-center">
      {buttons.map(button => {
        return (<div ><button className={button === activeTab ? 'boton mx-3 mb-0 buttonTab activo' : 'boton hoverBlanco  mx-3 mb-0 buttonTab'} onClick={() => changeTab(button)}>{button}</button><hr className={button === activeTab ? 'lineActiva mx-3 mb-3' : 'line mb-3'} /></div>)
      })}
    </div>
  )
}

const Tab = props => {
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}
export default CompraTicketsMobile;