import React, { useState } from "react";

import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import $ from 'jquery';
import "react-datepicker/dist/react-datepicker.css";
import { wrapper } from 'axios-cookiejar-support';
import * as app from "../fetch/fetchApi";
registerLocale('es', es)
const axios = require('axios');


const jar = app.jar;
const client = wrapper(axios.create({ jar }));

const instance = axios.create({
  baseURL: 'https://silvio.estacas.dev.m22.mx/',
  timeout: 1000,
  headers: {
    // Overwrite Axios's automatically set Content-Type
    crossDomain: true,
    'Content-Type': 'application/json'
  },
});

const CompraTickets = (data) => {
  console.log(data)
  const [alerta, setAlerta] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [startDate2, setStartDate2] = useState(null);
  const busqueda = () => {
    console.log(startDate)
    console.log(startDate2)
    var d1 = new Date(startDate);
    var d2 = new Date(startDate2);
    console.log(d1.getDate())
    console.log(d2.getDate())
    if (startDate == null || startDate2 == null) {
      console.log('clic')
      document.getElementById('toast-message').style.display = 'block'
      // $('#toast-message').css('display','block')
      setTimeout(() => {
        // $('#toast-message').css('display','none')
        document.getElementById('toast-message').style.display = 'none'

      }, 2000);

    } if (d1.getDate() === d2.getDate()) {
      console.log('entro')
      document.getElementById('toast-message').style.display = 'block'
      // $('#toast-message').css('display','block')
      setTimeout(() => {
        // $('#toast-message').css('display','none')
        document.getElementById('toast-message').style.display = 'none'

      }, 2000);

    } if (d1 > d2) {
      console.log('entro 2')
      document.getElementById('toast-message').style.display = 'block'
      // $('#toast-message').css('display','block')
      setTimeout(() => {
        // $('#toast-message').css('display','none')
        document.getElementById('toast-message').style.display = 'none'

      }, 2000);

    }
    else if (d1.getDate() < d2.getDate()) {
      console.log('fecha inicio ' + startDate)
      console.log('fecha fin ' + startDate2)
      let dateInicio = [
        startDate.getFullYear(),
        ('0' + (startDate.getMonth() + 1)).slice(-2),
        ('0' + startDate.getDate()).slice(-2)
      ].join('-');

      let dateFin = [
        startDate2.getFullYear(),
        ('0' + (startDate2.getMonth() + 1)).slice(-2),
        ('0' + startDate2.getDate()).slice(-2)
      ].join('-');
      console.log('fecha inicio final ' + dateInicio)
      console.log('fecha fin final ' + dateFin)
      localStorage.setItem('nombreSitio', 'todos')
      const isBrowser = typeof window !== "undefined"

      if (isBrowser) {
        window.location.href = '/reserva?fechaInicio=' + dateInicio + "&fechaFin=" + dateFin
      }
    }
  }




  const isBrowser = typeof window !== "undefined"

  if (isBrowser) {


    $("formulario").submit(function (event) {
      /* var formData = {
         name: $("#name").val(),
         email: $("#email").val(),
         superheroAlias: $("#superheroAlias").val(),
       };
   
       $.ajax({
         type: "POST",
         url: "process.php",
         data: formData,
         dataType: "json",
         encode: true,
       }).done(function (data) {
         console.log(data);
       });*/
      console.log('pruebas')

      event.preventDefault();
    });





    setTimeout(() => {
      handleLocalStorage();
      $("#formulario").unbind('submit').on("submit", function (event) {
        console.log($("#edit-field-entrada-general-und-0-value").val())
        console.log($("#edit-field-entrada-menor-und-0-value").val())

        if ($("#edit-field-entrada-general-und-0-value").val() != 0 || $("#edit-field-entrada-menor-und-0-value").val() != 0) {
          var formData = []
          if ($("#edit-field-entrada-general-und-0-value").val() != 0 && $("#edit-field-entrada-menor-und-0-value").val() == 0) {
            formData.push({ id: data.data.data[0].idproducto, cantidad: $("#edit-field-entrada-general-und-0-value").val() })
          } if ($("#edit-field-entrada-general-und-0-value").val() == 0 && $("#edit-field-entrada-menor-und-0-value").val() != 0) {
            formData.push({ id: data.data.data[1].idproducto, cantidad: $("#edit-field-entrada-menor-und-0-value").val() })

          } else if ($("#edit-field-entrada-general-und-0-value").val() != 0 && $("#edit-field-entrada-menor-und-0-value").val() != 0) {
            formData.push({
              id: data.data.data[0].idproducto, cantidad: $("#edit-field-entrada-general-und-0-value").val()
            }, {
              id: data.data.data[1].idproducto, cantidad: $("#edit-field-entrada-menor-und-0-value").val(),
            })
          }



          var datos = JSON.stringify(formData, 4, null)
          console.log(datos)

          client.post(app.url + '/node-api/m22_insert_to_carrito/', datos, {
            // method: 'POST', // or 'PUT'
            // credentials: 'include',
            withCredentials: true,
            //jar:CookieJar,
            //data: datos, // data can be `string` or {object}!
            headers: {
              // Overwrite Axios's automatically set Content-Type
              crossDomain: true,
              'Content-Type': 'application/json'
            },
          })
            .then(function (response) {
              console.log(response);
              console.log('Success:', response)
              //console.log(jar.getCookiesSync('https://silvio.estacas.dev.m22.mx/node-api/m22_insert_to_carrito/'));
              setAlerta('success')
              setTimeout(() => {
                setAlerta('')
                // window.location.reload();
                peticion();
              }, 3000);
            })
            .catch(function (error) {
              console.log(error);
              console.error('Error:', error)
              setAlerta('error')
              setTimeout(() => {
                setAlerta('')
              }, 3000);
            });
          /*fetch('https://silvio.estacas.dev.m22.mx/node-api/m22_insert_to_carrito/', {
            method: 'POST', // or 'PUT'
            credentials: 'include',
            body: JSON.stringify(formData, null, 4), // data can be `string` or {object}!
            headers:{
              'Content-Type': 'application/json'
            }
          }).then(res => res.json())
          .catch(error => {
             console.error('Error:', error)
             setAlerta('error')
             setTimeout(() => {
               setAlerta('')
            }, 3000);
         })
          .then(response => {
             console.log('Success:', response) 
            setAlerta('success')
            setTimeout(() => {
               setAlerta('')
            }, 3000);
         });*/

          $('#formulario')[0].reset();
          document.getElementById('visita').innerHTML = "0 visitantes"
          event.preventDefault()
        } else if ($("#edit-field-entrada-general-und-0-value").val() == 0 && $("#edit-field-entrada-menor-und-0-value").val() == 0) {
          setAlerta('error')
          console.log(alerta)
          setTimeout(() => {
            setAlerta('')
          }, 3000);
        }
      })
    }, 2000);
  }

  var precioTotal = 0
  var cantidadGeneral = 0;
  var cantidadMenor = 0;
  const suma = (precio) => {
    console.log(precio)
    console.log(data.data.data[0].precio)

    if (precio == data.data.data[0].precio) {
      cantidadGeneral = cantidadGeneral + 1
      console.log(cantidadGeneral)
      document.getElementById('edit-field-entrada-general-und-0-value').value = cantidadGeneral;
    } else if (precio == data.data.data[1].precio) {
      cantidadMenor = cantidadMenor + 1;
      console.log(cantidadMenor)
      document.getElementById('edit-field-entrada-menor-und-0-value').value = cantidadMenor;


    }
    precio = precio.replace('$', '')
    let precioTotalFloat = parseFloat(precio)
    precioTotal = precioTotalFloat + precioTotal;
    console.log(precioTotal)
    document.getElementById('edit-field-visitantes-und-0-value').value = '$' + precioTotal + '.00'
    document.getElementById('visita').innerHTML = (cantidadGeneral + cantidadMenor) + ' visitantes'

  }

  const resta = (precio) => {
    var precio2 = precio
    precio = precio.replace('$', '')
    let precioTotalFloat = parseFloat(precio)
    if (precio2 == data.data.data[0].precio && document.getElementById('edit-field-entrada-general-und-0-value').value > 0) {
      cantidadGeneral = cantidadGeneral - 1
      console.log(cantidadGeneral)
      document.getElementById('edit-field-entrada-general-und-0-value').value = cantidadGeneral;
      precioTotal = precioTotal - precioTotalFloat;
    } else if (precio2 == data.data.data[1].precio && document.getElementById('edit-field-entrada-menor-und-0-value').value > 0) {
      cantidadMenor = cantidadMenor - 1;
      console.log(cantidadMenor)
      document.getElementById('edit-field-entrada-menor-und-0-value').value = cantidadMenor;
      precioTotal = precioTotal - precioTotalFloat;


    }
    document.getElementById('edit-field-visitantes-und-0-value').value = '$' + precioTotal + '.00'
    document.getElementById('visita').innerHTML = (cantidadGeneral + cantidadMenor) + ' visitantes'
    console.log(precioTotal)
  }



  async function peticion() {


    await client.get(app.url + '/node-api/m22_get_cantidad/1', {
      // method: 'POST', // or 'PUT'
      // credentials: 'include',
      withCredentials: true,
      //jar:CookieJar,
      //data: datos, // data can be `string` or {object}!
      headers: {
        // Overwrite Axios's automatically set Content-Type
        crossDomain: true,
        'Content-Type': 'application/json'
      },
    })
      .then(function (response) {
        // setData(response.data[0])
        localStorage.setItem('cantidadProds', response.data[0])
        //cantidad=response.data[0]
        console.log(response.data[0]);

      })
      .catch(function (error) {
        console.log(error)
      });
  }

  const handleLocalStorage = () => {
    window.dispatchEvent(new Event("storage"));
  };
  const addMonths = () => {
    var fechaHoy = new Date(Date.now())

    var fechaFinal = new Date()
    fechaFinal.setDate(fechaHoy.getDate() + 180)
    console.log(fechaFinal)
    return fechaFinal
  }

  return (
    <div className={'block block-entityform-block block-entityform-block-compra-tickets block-entityform-block-compra-tickets d-none d-sm-block paddingBlock'} style={{ height: '231px' }} >

      <div className="tabs">
        <Tabs>
          <Tab label="Entradas">
            <div id="clientsidevalidation-compra-tickets-entityform-edit-form-errors" class="messages error clientside-error" style={{ display: 'none' }}><ul></ul></div>
            <form class="entityform entitytype-compra_tickets-form" action="javascript:void(0);" method={'post'} id={'formulario'} accept-charset="UTF-8" novalidate="novalidate"><div class="general"><div class="pre-instructions"></div>
              <div class="required-fields group-wrapper-form field-group-html-element" style={{ width: '100%' }}>
                <div class="required-fields group-entradas field-group-div visible d-flex row ms-0" style={{ width: '100%' }}>

                  <div class="field-type-number-integer field-name-field-entrada-general field-widget-number form-wrapper pe-4 col-3" id="edit-field-entrada-general"><div id="field-entrada-general-add-more-wrapper"><div class="form-item form-type-textfield form-item-field-entrada-general-und-0-value mt-md-0">
                    <div class="description mb-2"><label id="productos" data-precio={data.data.data[0].precio} data-sku={data.data.data[0].sku} data-componente="ticket home" for="edit-field-entrada-general-und-0-value ">Entrada General </label>   <div className="tooltips ms-2">?<span data-tooltip="" class="  tooltiptext entradaGeneral" data-selector="tooltip-kt21gffp1" aria-describedby="tooltip-kt21gffp1" >Mide mas de 1.25m.</span> </div> </div>
                    <div class="input-group" style={{ display: 'unset' }} ><button type="button" onClick={() => { resta(data.data.data[0].precio) }} class="less button">-</button><input type="number" id="edit-field-entrada-general-und-0-value" name="field_entrada_general[und][0][value]" data-titulo={data.data.data[0].titulo} data-precio={data.data.data[0].precio} data-sku={data.data.data[0].sku} value="0" size="12" maxlength="10" class="form-text label-up data" />
                      <button type="button" onClick={() => { suma(data.data.data[0].precio) }} class="more button">+</button></div></div>
                  </div></div>

                  <div class="field-type-number-integer field-name-field-entrada-menor field-widget-number form-wrapper col-3 p-md-0 pe-lg-3" id="edit-field-entrada-menor"><div id="field-entrada-menor-add-more-wrapper">
                    <div class="form-item form-type-textfield form-item-field-entrada-menor-und-0-value mt-md-0">


                      <div class="description mb-2"><label id="productos" data-precio={data.data.data[1].precio} data-sku={data.data.data[1].sku} data-componente="ticket home" for="edit-field-entrada-menor-und-0-value">Entrada Menor </label> <div className="tooltips ms-2">?<span data-tooltip="" class="tooltiptext" data-selector="tooltip-kt21gffp1" aria-describedby="tooltip-kt21gffp1" title="En caso de no acreditar la estatura, tendrás que pagar la diferencia en taquilla.">En caso de no acreditar la estatura, tendrás que pagar la diferencia en taquilla.</span> </div></div>
                      <div class="input-group" style={{ display: 'unset' }}><button type="button" onClick={() => { resta(data.data.data[1].precio) }} class="less button">-</button><input type="number" id="edit-field-entrada-menor-und-0-value" name="field_entrada_menor[und][0][value]" data-titulo={data.data.data[1].titulo} data-precio={data.data.data[1].precio} data-sku={data.data.data[1].sku} value="0" size="12" maxlength="10" class="form-text label-up data" />
                        <button type="button" onClick={() => { suma(data.data.data[1].precio) }} class="more button">+</button></div></div>
                  </div></div>

                  <div class="required-fields group-summary-form field-group-html-element resumen-group col-3 px-lg-4 mt-lg-0 mt-md-0 ps-md-0" style={{ width: '22%' }}><div className="d-md-flex"><span className="justify-content-start">Resumen</span></div><div class="field-type-text field-name-field-visitantes field-widget-text-textfield form-wrapper ms-md-0" id="edit-field-visitantes"><div id="field-visitantes-add-more-wrapper"><div class="form-item form-type-textfield form-item-field-visitantes-und-0-value">
                    <label for="edit-field-visitantes-und-0-value" id={'visita'} class="label-up data visitantes">0 visitantes</label>
                    <input class="text-full form-text label-up data labelNormal" type="text" id="edit-field-visitantes-und-0-value" name="field_visitantes[und][0][value]" value="$0" size="60" maxlength="50" />
                  </div>
                  </div></div></div>

                  <div className={'col-3 pe-4 '}>
                    <input type="hidden" name="form_build_id" value="form-4yv-qdkwR7V4OtjbkrT-I1MWl1i2vxoxVo1EkYUf4ok" />
                    <input type="hidden" name="form_id" value="compra_tickets_entityform_edit_form" />
                    <button class="secondary button radius gtm-comprar form-submit palabraBoton" id="agregarCarrito" name="op" value="comprar" style={{ width: '128px' }} type="submit">Comprar</button>
                  </div>
                  {alerta == 'success' ? <div id="add-to-cart-toast-message" style={{ visibility: 'visible' }} class="paragraph-sb">Producto agregado a carrito</div> : ''}
                  {alerta == 'error' ? <div id="toast-message" class=""><span class="paragraph-sb"><strong>Error:</strong> por favor ingresa un número de entradas.</span></div> : ''}

                </div></div>

              <p class="paragraph-sb tarifas mt-4" style={{ fontSize: '16px' }}>*Para más información sobre precios puedes visitar nuestra sección de <a href={app.url + "/tarifas"} class="btn-tex-mob-sb links">Tarifas</a></p>
            </div>
            </form>
          </Tab>
          <Tab label="Hospedajes">
            <div className={'row mt-3 mb-4'}>
              <label style={{ marginBottom: 0, alignSelf: 'center', fontSize: '16px', display: 'block' }} className={'descripcion col-1 mx-4 p-0'}>Llegada</label>
              {/*<Calendar className={'col-4'} />*/}
              <div className={"col-3 d-flex align-middle"} style={{ alignItems: 'center' }}>
                <DatePicker selected={startDate} dateFormat="dd/MM/yyyy" onChange={(date) => setStartDate(date)} locale="es" placeholderText="Fecha" className={'calendario labelNormal'} minDate={new Date() - 5} />
              </div>
              <label style={{ marginBottom: 0, alignSelf: 'center', fontSize: '16px', display: 'block' }} className={'descripcion col-1 mx-3'}>Salida</label>
              <div className={"col-3 d-flex align-middle"} style={{ alignItems: 'center' }}>
                <DatePicker selected={startDate2} dateFormat="dd/MM/yyyy" onChange={(date) => { setStartDate2(date); console.log(date.getDate() + 1) }} locale="es" placeholderText="Fecha" className={'calendario col-4 labelNormal'} maxDate={addMonths()} minDate={startDate ? startDate : new Date() - 5} />
              </div>
              {/*<button class="boton-azul col-2" onClick={() => busqueda()} style={{marginTop:0}} id="edit-submit--4" name="op" value="Agregar a mi compra" type="submit">Buscar</button>*/}
              <button class="secondary button col-2 radius form-submit mt-0 palabraBoton" id="buscarFechas" onClick={() => busqueda()} name="op" value="comprar" style={{ width: '128px', height: '48px' }} type="submit">Buscar</button>

              {/*
             <Calendar2 />
  */}
              <div id="toast-message" class="" style={{ display: 'none' }}><span class="paragraph-sb"><strong>Error:</strong> Ingrese fechas correctamente</span></div>

            </div>
            <p class="paragraph-sb tarifas margenes" style={{ fontSize: '16px', display: 'block' }}>*Para más información sobre precios puedes visitar nuestra sección de <a href={app.url + "/tarifas"} class="btn-tex-mob-sb links">Tarifas</a></p>

          </Tab>
        </Tabs>
      </div>


    </div>
  );
};


class Tabs extends React.Component {
  state = {
    activeTab: this.props.children[0].props.label
  }
  changeTab = (tab) => {

    this.setState({ activeTab: tab });
  };
  render() {

    let content;
    let buttons = [];
    return (
      <div>
        {React.Children.map(this.props.children, child => {
          buttons.push(child.props.label)
          if (child.props.label === this.state.activeTab) content = child.props.children
        })}

        <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab} />
        <div className="tab-content">{content}</div>

      </div>
    );
  }
}
const TabButtons = ({ buttons, changeTab, activeTab }) => {

  return (
    <div className="tab-buttons mb-0">
      {buttons.map(button => {
        return (<div><button className={button === activeTab ? 'boton mx-3 buttonTab activo' : 'boton hoverBlanco  mx-3 buttonTab'} onClick={() => changeTab(button)}>{button}</button><hr className={button === activeTab ? 'lineActiva mx-3 mb-3' : 'line mb-3'} /></div>)
      })}
    </div>
  )
}

const Tab = props => {
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}
export default CompraTickets;