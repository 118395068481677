import React from "react";
import $ from "jquery"
import { LazyLoadImage } from 'react-lazy-load-image-component';

//let image = "https://estacas.dev.m22.mx/sites/all/themes/tickets/img/Logo.svg";

const Card = ({ id, data }) => {
  const isBrowser = typeof window !== "undefined"

  console.log(id)
  console.log(data)
  let datos = [];
  datos = data;
  let url = datos[id - 1].nombre;

  // console.log(id)


  const putData = (id) => {

    var modal = document.getElementById("myModal2");

    $('#menuNav').css('z-index', '1')
    setTimeout(() => {
      console.log('clic ' + data[id - 1].nombre);
      modal.style.display = "block";
      $('#tituloModal').text(data[id - 1].nombre);
      $('#tituloModal2').text(data[id - 1].nombre);

      $('#srcModal').attr('src', data[id - 1].card_image);
      $('#srcModal2').attr('src', data[id - 1].card_image);

      $('#precioModal').text(data[id - 1].precio);
      $('#precioModal2').text(data[id - 1].precio);

      $('#letraChicaModal').text(data[id - 1].desccard);
      $('#idModal').text(data[id - 1].idproducto);

    }, 1000);
  }

  setTimeout(() => {
    console.log(id)
    var modal = document.getElementById("myModal2");
    /* $("#comprarComplementa"+id).unbind('click').click(function()
    {
      console.log('clic')
     $('#menuNav').css('z-index','1')
      
      modal.style.display = "block";
 
     /* if ($("#author_bio_wrap_toggle").text() == "Expand Author Details")
        {			
          $("#author_bio_wrap_toggle").html("Hide Author Details")
        }
      else 
        {		
          $("#author_bio_wrap_toggle").text("Expand Author Details")
        }
      
    });*/


    $("#close2").click(function () {
      modal.style.display = "none";

    });
    if (isBrowser) {
      window.onclick = function (event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      }
    }
  }, 2000);

  return (

    <div className={'views-row views-row-' + id + ' views-row-odd views-row-first my-3'}>
      {console.log(datos[id - 1].sku)}
      <div class="views-field views-field-field-imagen-de-card-home views-field-field-multimedia">
        <div class="field-content">
          <LazyLoadImage src={datos[id - 1].card_image} loading="lazy" class="b-lazy b-loaded" width="530" height="379" alt="" />
        </div>
      </div>
      <div class="title-price views-fieldset " data-module="views_fieldsets">
        <div class="views-field views-field-view-node-1">
          <span class="field-content">
            <p className="tituloInteres">{url == "La Estancia. Hotel para perros" ? 'La Estancia' : url}</p>
            <p id="productos" data-sku={datos[id - 1].sku} data-precio={datos[id - 1].precio} data-componente="card atracciones home" style={{ display: 'none' }}>{data[id - 1].nombre}</p>
          </span>
        </div>
        <div class="views-field views-field-commerce-price">
          <span class="field-content title-price emphasis-v">{datos[id - 1].precio}</span>
        </div>
      </div>
      <div class="views-field views-field-field-desccardhome">
        <div class="field-content">
          {<div dangerouslySetInnerHTML={{ __html: datos[id - 1].description }} />}
        </div>
      </div>
      <div class="views-field views-field-view-node">
        <span class="field-content">
          <div className="d-xl-flex d-none justify-content-start" style={{ paddingLeft: '40px', paddingRight: '40px' }}>
            { /* <div dangerouslySetInnerHTML={{__html: datos[id-1].link_more_info}} />*/}
            <a href={datos[id - 1].url}> <button class="secondary button radius form-submit mb-3 botonAdicional" style={{ marginTop: '0px', float: 'unset' }} name="op" value="comprar" type="submit">Ver más info</button></a>
            <button class="ms-5 secondary button radius form-submit col-lg-5 mt-lg-0 mb-lg-3 col-md-2 mt-md-2 mb-md-3 col-4 mb-3 mt-2" onClick={() => { putData(id) }} id={"atracciones" + id} name="op" value="Buscar" style={{ width: '124px' }} type="submit">Comprar</button>

          </div>
          <div className="row d-xl-none d-flex justify-content-center" style={{ paddingLeft: '40px', paddingRight: '40px' }}>
            { /* <div dangerouslySetInnerHTML={{__html: datos[id-1].link_more_info}} />*/}
            <div className="d-flex justify-content-center col-12">
              <a href={datos[id - 1].url}> <button class="secondary button radius form-submit mb-3 botonAdicional" style={{ marginTop: '0px', float: 'unset' }} name="op" value="comprar" type="submit">Ver más info</button></a>

            </div>
            <div className="d-flex justify-content-center col-12">
              <button class=" secondary button radius form-submit col-lg-5 mt-lg-0 mb-lg-3 col-md-2 mt-md-2 mb-md-3 col-4 mb-3 mt-2" onClick={() => { putData(id) }} id={"atracciones" + id} name="op" value="Buscar" style={{ width: '124px' }} type="submit">Comprar</button>

            </div>
          </div>
        </span>
      </div>
    </div >


  );
};

export default Card;
