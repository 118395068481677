import React from "react";


const HeaderCard = () => {
  return (
    <div class="view-header">
        <h2 class="subtitle home title-2-sb">Agua turquesa, naturaleza y diversión</h2>
        <p class="page-description home paragraph-sb">Acampa a la luz de las estrellas, al lado de una <br/> fogata si prefieres.</p>
        {/*<img src="estacasreact/src/sites/default/files/Combined Shape.png" width={'100px'} height={'100px'}/>*/}
    </div>
  );
};

export default HeaderCard;