import React, { useEffect, useState, } from "react";
import Banner from "../components/banner";
import BackgroundImage from "../components/background_image";
import { withPrefix } from "gatsby";
/*import * as datosM from "../../static/drupal/dummy-main-menu";
import * as datosT from "../../static/drupal/dummy-tickets";
import * as datosA from "../../static/drupal/dummy-atracciones.js";
import * as datosE from "../../static/drupal/dummy-eventos";
import * as datosI from "../../static/drupal/dummy-instragram";
import * as datosFM from "../../static/drupal/dummy-footer-menu";
*/

import PreFooter from "../components/prefooter";
import NewsLetter from "../components/newsletter";
import MenuFooter from "../components/menu_footer";
import Politicas from "../components/politicas";
import Redes from "../components/redes";
import Copyright from "../components/copyright";
import HeaderCard from "../components/header_card";
import ContentCard from "../components/content_card";
import CardEvento from "../components/card_eventos";
import ContentInstagram from "../components/content_instragram";

import CarouselCardAtracciones from "../components/carousel_card_atracciones";
import BannerMobile from "../components/banner_mobile";
import ModalComplementa from "../components/modal_complementa";
import { wrapper } from 'axios-cookiejar-support';
import * as app from "../fetch/fetchApi";
import { Link, useStaticQuery, graphql } from 'gatsby'

import { Helmet, } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
import $, { getJSON } from 'jquery'
import { LazyLoadComponent } from "react-lazy-load-image-component";

import Mapa from "../components/mapa";
import CompraTicketsMobile from "../components/tickets_compra_mobile";
import TagManager from 'react-gtm-module'


const axios = require('axios');


const jar = app.jar;
const client = wrapper(axios.create({ jar }));
/*export class Header extends React.Component {


    render(){
        <div>
        <Banner />
        <BackgroundImage />
        
        </div>
    }
}*/

/*const Header = () => {
    return (
        <div>
        <PreFooter />
        <footer className={'l-footer panel'} role={'contentinfo'}>
            <div className={'footer'}>
            <NewsLetter />
            <MenuFooter />
            <Politicas />
            <Redes />
            </div>
            <Copyright />
        </footer>
        </div>
    );
  };
  */

/*const Header = () => {
  return (
      
      <main className={'row l-main header-visible'}>
          <div className={' main columns'}>
      <section className={'block block-views block-views-cards-home-atracciones-block block-views-cards-home-atracciones-block'}>
          <div className={'view view-cards-home-atracciones view-id-cards_home_atracciones view-display-id-block view-dom-id-a1d681395865d9cc26a3b0abd78889c2'}>
              <HeaderCard />
              <ContentCard />
              <div class="view-footer"> <a href="/atracciones" class="more-content btn-tex-mob-sb">Ver todos las atracciones</a></div>
          </div>
      </section> 
      </div>
      </main>
     
  );
};*/
/*
  export class Header extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {
          data: undefined,
          dataInstagram:undefined,
          dataEvento:undefined,
          dataMenu:undefined,
          dataMenuFooter: undefined,
          dataTickets:undefined
        };
    }


    componentDidMount(){

      $( "#map-container" ).click(function() {
        alert( "Handler for .click() called." );
      });

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
           }
        };
          fetch("https://estacas.dev.m22.mx/api/v1/views/cards_home_atracciones?display_id=services_1&format_output=0  ",requestOptions)
        .then((response) => {
          console.log(response.status);
          if(response.status===200) {
            //console.log(response.json())
            var data= response.json();
            console.log(data)
            
            return data;
          } else {
           return false;
          }
        })
        .then((recurso) => {
          console.log(recurso)
          if (recurso!==undefined) {
            this.setState({
                data: recurso,
              }); 
          }
          
    })

    const requestOptions2 = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
       }
    };
      fetch("https://estacas.dev.m22.mx/api/v1/views/instagram_slider?display_id=services_1&format_output=0",requestOptions2)
    .then((response) => {
      console.log(response.status);
      if(response.status===200) {
        //console.log(response.json())
        var data= response.json();
        console.log(data)
        
        return data;
      } else {
       return false;
      }
    })
    .then((recurso) => {
      console.log(recurso)
      if (recurso!==undefined) {
        this.setState({
            dataInstagram: recurso,
          }); 
      }
      
})


const requestOptions3 = {
  method: 'GET',
  headers: { 'Content-Type': 'application/json',
 }
};
fetch("https://estacas.dev.m22.mx/api/v1/views/evento_home_2019?display_id=services_1&format_output=0",requestOptions3)
.then((response) => {
console.log(response.status);
if(response.status===200) {
  //console.log(response.json())
  var data= response.json();
  console.log(data)
  
  return data;
} else {
 return false;
}
})
.then((recurso) => {
console.log(recurso)
if (recurso!==undefined) {
  this.setState({
      dataEvento: recurso,
    }); 
}

})


/*const requestOptions4 = {
  method: 'GET',
  headers: { 'Content-Type': 'application/json',
 }
};
fetch("https://estacas.dev.m22.mx/js-api/m22_item_menu/main-menu",requestOptions4)
.then((response) => {
console.log(response.status);
if(response.status===200) {
  //console.log(response.json())
  var data= response.json();
  console.log(data)
  
  return data;
} else {
 return false;
}
})
.then((recurso) => {
console.log(recurso)
if (recurso!==undefined) {
  this.setState({
      dataMenu: recurso,
    }); 
}

})

const requestOptions5 = {
  method: 'POST',
  headers: { 'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': 'http://localhost:8000/',
 }
};
fetch("https://silvio.estacas.dev.m22.mx/node-api/m22_item_menu/main-menu")
.then((response) => {
console.log(response.status);
if(response.status===200) {
  //console.log(response.json())
  var data= response.json();
  console.log(data)
  
  return data;
} else {
 return false;
}
})
.then((recurso) => {
console.log(recurso)
if (recurso!==undefined) {
  this.setState({
      dataMenu: recurso,
    }); 
}

})


fetch("https://silvio.estacas.dev.m22.mx/node-api/m22_item_menu/menu-foooter-menu-2019")
.then((response) => {
console.log(response.status);
if(response.status===200) {
  //console.log(response.json())
  var data= response.json();
  console.log(data)
  
  return data;
} else {
 return false;
}
})
.then((recurso) => {
console.log(recurso)
if (recurso!==undefined) {
  this.setState({
      dataMenuFooter: recurso,
    }); 
}

})



  fetch('https://silvio.estacas.dev.m22.mx/node-api/views/prueba_tickets_componente?display_id=services_1&format_output=0')
  .then((response) => {
console.log(response.status);
if(response.status===200) {
  //console.log(response.json())
  var data= response.json();
  console.log(data)
  
  return data;
} else {
 return false;
}
})
.then((recurso) => {
console.log(recurso)
if (recurso!==undefined) {
  this.setState({
      dataTickets: recurso,
    }); 
}

})

}
      render(){
          let datos = this.state.data;
          let datosInstagram = this.state.dataInstagram;
          let datosEventos = this.state.dataEvento
          let datosMenu = this.state.dataMenu
          let datosMenuFooter = this.state.dataMenuFooter
          let datosTickets = this.state.dataTickets
          if (datos===undefined||datosInstagram===undefined||datosEventos===undefined||datosMenu===undefined||datosMenuFooter===undefined
            ||datosTickets===undefined) {
             return <h1>Loading...</h1>;
          }else{
           /* return(
              <div>
              
                <Banner />
                <ImagenSitioInteres />
                <SitioInteres />
                
                <CarouselCardComplementa />
                <div>
        <footer className={'l-footer panel'} role={'contentinfo'}>
            <div className={'footer'}>
            <NewsLetter />
            <MenuFooter />
            <Politicas />
            <Redes />
            </div>
            <Copyright />
        </footer>
        </div>
              </div>
            )
    return (
        <div>
       
<Banner data={datosMenu}/>
<BackgroundImage data={datosTickets} />
        
        <main className={'l-main header-visible'} role="main">
            <div className={' main'}>
            
            <div style={{padding: '0 10%'}}>  
        <section className={'block block-views block-views-cards-home-atracciones-block block-views-cards-home-atracciones-block'}>
        <CompraTicketsMobile data={datosTickets} /> 
        
            <div className={'view view-cards-home-atracciones view-id-cards_home_atracciones view-display-id-block view-dom-id-a1d681395865d9cc26a3b0abd78889c2'}>
                <HeaderCard />
                <ContentCard data={datos}/>
                
            </div>
        </section> 
        <Mapa />
       <ContentInstagram data={datosInstagram}/>
        <section class="block block-views block-views-evento-home-2019-block block-views-evento-home-2019-block">
   <h2 class="block-title">Eventos</h2>
   <div class="view view-evento-home-2019 view-id-evento_home_2019 view-display-id-block view-dom-id-d1eb1bfdcbb295210c7992b81e1319cc">
      <div class="view-content slick-initialized slick-slider">
        
            
        <div style={{margin: '0 auto',
          color: '#333',
          display: 'block'}}>
        <CardEvento  data={datosEventos}/>
        </div>
         
      </div>
      <div class="view-footer"> <a href="/eventos" class="more-content btn-tex-mob-sb">Ver todos los eventos</a></div>
   </div>
  </section>
  </div>
        </div>
        
        </main>
        <PreFooter />
        <footer className={'l-footer panel'} role={'contentinfo'}>
            <div className={'footer'}>
            <NewsLetter />
            <MenuFooter data={datosMenuFooter}/>
            <Politicas />
            <Redes />
            </div>
            <Copyright />
        </footer>
        <span id={'mostrarTooltip'} data-selector="tooltip-kxqp5vmd1" id="tooltip-kxqp5vmd1" class="tooltip tip-top custom-open"  role="tooltip" style={{visibility: 'visible', width: 'auto', inset: '610.594px auto auto 739.969px', }}>En caso de no acreditar la estatura, tendrás que pagar la diferencia en taquilla.<span class="nub"></span></span>
        </div>
    );
  };
}
  }
*/

const Header = () => {
  const [datos, setData] = useState([])
  const [datosInstagram, setDataInstagram] = useState([])
  const [datosEventos, setDataEvento] = useState([])
  const [datosMenu, setDataMenu] = useState({})
  const [datosMenuFooter, setDataMenuFooter] = useState({})
  const [datosTickets, setDataTickets] = useState([{
    idproducto: "",
    precio: "",
    titulo: ""
  }, {
    idproducto: "",
    precio: "",
    titulo: ""
  }])


  const isBrowser = typeof window !== "undefined"
  let width;
  //var dummyAtracciones = require("../../static/drupal/dummy-atracciones.js")
  /* function getval() {
     jQuery.getJSON('../../static/drupal/dummy-atracciones.json', function(data) {
         // You have to use "data" here
         return data;
     });
 }*/
  //console.log = function () { };

  var dummyAtracciones;
  function callbackFuncWithData(data) {
    console.log(data)
    setData(data)
    dummyAtracciones = data;
    console.log("data", data)
    console.log("dummyAtracciones", dummyAtracciones)
    $.each(data, function (i, field) {
      //$("div").append(field + " ");
      console.log("field", field)
      //dummyAtracciones.push(Object.values(field))
    });
  }
  /*
      function getDatosAtracciones() {
        getJSON('../../static/drupal/dummy-atracciones.json', function (result) {
          // dummyAtracciones.push(Object.values(result))
          $.each(result, function (i, field) {
            //$("div").append(field + " ");
            console.log(field)
    
            //dummyAtracciones.push(Object.values(field))
          });
          dummyAtracciones = JSON.stringify(result)
          setData(result)
          console.log(JSON.stringify(result))
        }
        );
      }
      function getDatosEventos() {
        getJSON('../../static/drupal/dummy-eventos.json', function (result) {
          // dummyAtracciones.push(Object.values(result))
          $.each(result, function (i, field) {
            //$("div").append(field + " ");
            console.log(field)
    
            //dummyAtracciones.push(Object.values(field))
          });
          dummyAtracciones = JSON.stringify(result)
          setDataEvento(result)
          console.log(JSON.stringify(result))
        }
        );
      }
    */
  /*
    function getDatosFooterMenu() {
      getJSON('../../static/drupal/dummy-footer-menu.json', function (result) {
        // dummyAtracciones.push(Object.values(result))
        $.each(result, function (i, field) {
          //$("div").append(field + " ");
          console.log(field)
  
          //dummyAtracciones.push(Object.values(field))
        });
        dummyAtracciones = JSON.stringify(result)
        setDataMenuFooter(result)
        console.log(JSON.stringify(result))
      }
      );
    }
  
    function getDatosInstragram() {
      getJSON('../../static/drupal/dummy-instagram.json', function (result) {
        // dummyAtracciones.push(Object.values(result))
        $.each(result, function (i, field) {
          //$("div").append(field + " ");
          console.log(field)
  
          //dummyAtracciones.push(Object.values(field))
        });
        dummyAtracciones = JSON.stringify(result)
        setDataInstagram(result)
        console.log(JSON.stringify(result))
      }
      );
    }
  */
  /*
    function getDatosMainMenu() {
      getJSON('../../static/drupal/dummy-main-menu.json', function (result) {
        // dummyAtracciones.push(Object.values(result))
        $.each(result, function (i, field) {
          //$("div").append(field + " ");
          console.log(field)
  
          //dummyAtracciones.push(Object.values(field))
        });
        dummyAtracciones = JSON.stringify(result)
        setDataMenu(result)
        console.log(JSON.stringify(result))
      }
      );
    }
  */
  /*
  
    function getDatosTickets2() {
      getJSON('../../static/drupal/dummy-tickets.json', function (result) {
        // dummyAtracciones.push(Object.values(result))
        $.each(result, function (i, field) {
          //$("div").append(field + " ");
          console.log(field)
  
          //dummyAtracciones.push(Object.values(field))
        });
        dummyAtracciones = JSON.stringify(result)
        setDataTickets(result)
        console.log(JSON.stringify(result))
      }
      );

  if (window !== undefined) {
    setDataTickets(window.tickets)
    console.log("tickets", window.tickets)
  } else {

    console.log("esta indefinido")
  }
}*/


  if (typeof window !== 'undefined') {
    window.updateValues = () => {
      getDatosTickets()
      getDatosAtracciones()
      getDatosEventos()
      getDatosFooterMenu()
      getDatosInstragram()
      getDatosMainMenu()
    }
  }

  const getDatosTickets = () => {
    if (typeof window !== 'undefined') {
      if (window.tickets !== undefined) {
        setDataTickets(window.tickets)
      }
    }
  }

  const getDatosEventos = () => {
    if (typeof window !== 'undefined') {
      if (window.tickets !== undefined) {
        setDataEvento(window.dummyEventos)
      }
    }
  }

  const getDatosFooterMenu = () => {
    if (typeof window !== 'undefined') {
      if (window.tickets !== undefined) {
        setDataMenuFooter(window.dummyFooterMenu)
      }
    }
  }

  const getDatosInstragram = () => {
    if (typeof window !== 'undefined') {
      if (window.tickets !== undefined) {
        setDataInstagram(window.dummyInstagram)
      }
    }
  }
  const getDatosMainMenu = () => {
    if (typeof window !== 'undefined') {
      if (window.tickets !== undefined) {
        setDataMenu(window.dummyMenu)
      }
    }
  }

  const getDatosAtracciones = () => {
    if (typeof window !== 'undefined') {
      if (window.tickets !== undefined) {
        setData(window.dummyAtracciones)
      }
    }
  }
  useEffect(() => {
    getDatosAtracciones()
    getDatosEventos()
    getDatosFooterMenu()
    getDatosInstragram()
    getDatosMainMenu()
    getDatosTickets()
  }, [])








  /**
      data: undefined,
              dataInstagram:undefined,
              dataEvento:undefined,
              dataMenu:undefined,
              dataMenuFooter: undefined,
              dataTickets:undefined
     */

  //console.log(datos)
  /*useEffect(() => {
  
    setDataTickets(datosT.tickets)
  
    const fetchData = async () => {
    const data = await fetch(app.url + `/node-api/views/prueba_tickets_componente?display_id=services_1&format_output=0`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      const json = await data.json();
      setDataTickets(json)
    }
  
    document.addEventListener("DOMContentLoaded", function () {
      var lazyBackgrounds = [].slice.call(document.querySelectorAll(".l-footer"));
  
      if ("IntersectionObserver" in window && "IntersectionObserverEntry" in window && "intersectionRatio" in window.IntersectionObserverEntry.prototype) {
        let lazyBackgroundObserver = new IntersectionObserver(function (entries, observer) {
          entries.forEach(function (entry) {
            if (entry.isIntersecting) {
              entry.target.classList.add("visible");
              lazyBackgroundObserver.unobserve(entry.target);
            }
          });
        });
  
        lazyBackgrounds.forEach(function (lazyBackground) {
          lazyBackgroundObserver.observe(lazyBackground);
        });
      }
    });
    fetchData().catch(console.error);
  
  
  }, [])*/

  /* useEffect(() => {
   
     const fetchData = async () => {
       const data = await fetch(app.url + `/api/v1/views/prueba_evento_home_2019?display_id=services_1&format_output=0`, {
         method: 'GET',
         headers: {
           'Content-Type': 'application/json',
         }
       });
       const json = await data.json();
       setDataEvento(json);
     }
   
     fetchData().catch(console.error);
     // set data for the number of stars
   }, []) */

  /* useEffect(() => {
    // get data from GitHub api
    const fetchData = async () =>{
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      };
      const data = await fetch(app.url + `/api/v1/views/cards_atracciones?display_id=services_1&format_output=0`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      const json = await data.json();
      // set state with the result
      setData(json);
    }
    fetchData().catch(console.error);
  }, [])*/
  /*
    useEffect(() => {
      // get data from GitHub api
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      };
      fetch(app.url + `/api/v1/views/prueba_instagram_slider?display_id=services_1&format_output=0`, {
        method: 'GET',
   
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
          console.log(resultData)
          setDataInstagram(resultData)
        })
      // set data for the number of stars
    }, [])
   
    useEffect(() => {
      // get data from GitHub api
   
   
      client.get(app.url + '/node-api/m22_item_menu/menu-foooter-menu-2019', {
        // method: 'POST', // or 'PUT'
        // credentials: 'include',
        withCredentials: true,
        //jar:CookieJar,
        //data: datos, // data can be `string` or {object}!
        headers: {
          // Overwrite Axios's automatically set Content-Type
          crossDomain: true,
          'Content-Type': 'application/json'
        },
      })
        .then(function (response) {
          console.log(response.data);
   
          console.log(document.cookie);
          //console.log(response.json())
          setDataMenuFooter(response.data)
          console.log(datosMenu)
        })
        .catch(function (error) {
   
        });
      // set data for the number of stars
    }, [])
   
    /*const data = useStaticQuery(graphql`
     {
        allRestApiMenu {
      edges {
        node {
          id
        }
      }
    }
   
      }
    `)*/

  /*useEffect(() => {
    // const datosMenu = getJSON('../fetch/dummy-main-menu.json')
    console.log(datosM.dummyMenu)
    // get data from GitHub api
    /*
        client.get(app.url + '/node-api/m22_item_menu/main-menu', {
          // method: 'POST', // or 'PUT'
          // credentials: 'include',
          withCredentials: true,
          //jar:CookieJar,
          //data: datos, // data can be `string` or {object}!
          headers: {
            // Overwrite Axios's automatically set Content-Type
            crossDomain: true,
            'Content-Type': 'application/json'
          },
        })
          .then(function (response) {
            console.log(response.data);
    
            console.log(document.cookie);
            //console.log(response.json())
            setDataMenu(response.data)
            console.log(datosMenu)
          })
          .catch(function (error) {
    
          });
    setDataMenu(datosM.dummyMenu)
  }, [])*/

  if (isBrowser) {
    width = window.innerWidth
    console.log(width)
    //document.cookie="user=silvio"


    $(document).ready(function () {

      const datosRequire = require('../../static/drupal/dummy-atracciones.json')
      //setData(datosRequire)
    }



    );
  }

  const loading = <p>Loading</p>

  return (
    <div>
      <Helmet>
        {width > 1200 ? <link rel="preload" fetchpriority="high" as="image" href={"../../static/le_desktop_tripadvisor.webp"} /> : ''}
        {width > 450 && width < 1200 ? <link rel="preload" fetchpriority="high" as="image" href={"../../static/le_mobile_tripadvisor.webp"} /> : ''}
        {width < 450 ? <link rel="preload" fetchpriority="high" as="image" href={"../../static/le_tablet_tripadvisor.webp"} /> : ''}
        <script src={withPrefix('../../static/drupal/dummy-tickets.js')} type="text/javascript" />
        <script src={withPrefix('../../static/drupal/dummy-atracciones.js')} type="text/javascript" />
        <script src={withPrefix('../../static/drupal/dummy-footer-menu.js')} type="text/javascript" />
        <script src={withPrefix('../../static/drupal/dummy-main-menu.js')} type="text/javascript" />
        <script src={withPrefix('../../static/drupal/dummy-eventos.js')} type="text/javascript" />
        <script src={withPrefix('../../static/drupal/dummy-instagram.js')} type="text/javascript" />

      </Helmet>
      {width < 1100 ? <Banner data={datosMenu} /> : <BannerMobile data={datosMenu} />
      }
      {console.log(datosMenu)}
      {datosTickets.titulo != '' ? < BackgroundImage data={datosTickets} /> : ''}
      <main className={'l-main header-visible'} role="main">
        <ModalComplementa />

        <div className={' main'}>
          {/*<ModalLogin />*/}
          {/* <div className="timer-wrapper">
        <CountdownCircleTimer
          isPlaying
          duration={100}
          colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
          colorsTime={[10, 6, 3, 0]}
          onComplete={() => {console.log('completed')}}
        >
          {renderTime}
        </CountdownCircleTimer>
  </div>*/}
          <div className="paddingMain">
            <section className={'block block-views block-views-cards-home-atracciones-block block-views-cards-home-atracciones-block'}>
              {width < 450 && datosTickets.titulo != '' ? <CompraTicketsMobile data={datosTickets} /> : ''}
              <div className={'view view-cards-home-atracciones view-id-cards_home_atracciones view-display-id-block view-dom-id-a1d681395865d9cc26a3b0abd78889c2'}>
                <HeaderCard />
                <div className="">
                  <div className="d-none d-md-block"> <ContentCard data={datos} /> </div>
                  <div className="d-block d-md-none"><CarouselCardAtracciones data={datos} /></div>
                </div>
                <div class="view-footer"> <a href={app.url + "/atracciones"} class="more-content btn-tex-mob-sb">Ver todos las atracciones <img width='28' loading="lazy" height='16' src={app.url + "/sites/all/themes/tickets/2019/svg/flecha-derecha-azul.svg"} /></a> </div>
              </div>

            </section>
            <Mapa />

            <section class="block block-views block-views-evento-home-2019-block block-views-evento-home-2019-block">
              <h2 class="block-title">Eventos</h2>
              <div class="view view-evento-home-2019 view-id-evento_home_2019 view-display-id-block view-dom-id-d1eb1bfdcbb295210c7992b81e1319cc">
                <div class="view-content slick-initialized slick-slider">


                  <div style={{
                    margin: '0 auto',
                    color: '#333',
                    display: 'block'
                  }}>
                    {datosEventos.length > 0 ? <CardEvento data={datosEventos} /> : ''}
                  </div>

                </div>
                <div class="view-footer"> <a href={app.url + "/eventos"} class="more-content btn-tex-mob-sb">Ver todos los eventos <img width='28' loading="lazy" height='16' src={app.url + "/sites/all/themes/tickets/2019/svg/flecha-derecha-azul.svg"} /></a></div>
              </div>
              {datosInstagram.length > 0 ? <ContentInstagram data={datosInstagram} /> : ''}

            </section>
          </div>

        </div>

      </main>

      <PreFooter />
      <LazyLoadComponent>
        <footer id="foot" background="https://lasestacas.com/sites/all/themes/tickets/2019/Img/Footer_Mobile.jpg" className={'l-footer prueba panel lazy-background'} role={'contentinfo'}>

          <div className={'footer'}>
            <NewsLetter />
            <MenuFooter data={datosMenuFooter} />
            <Politicas />
            <Redes />
          </div>
          <Copyright />
        </footer>
      </LazyLoadComponent>
      <span id={'mostrarTooltip'} data-selector="tooltip-kxqp5vmd1" id="tooltip-kxqp5vmd1" class="tooltip tip-top custom-open" role="tooltip" style={{ visibility: 'visible', width: 'auto', inset: '610.594px auto auto 739.969px', }}>En caso de no acreditar la estatura, tendrás que pagar la diferencia en taquilla.<span class="nub"></span></span>

    </div>

  );
};

export default Header;